<template>
  <button
    v-if="userStore.isLoggedIn"
    :class="{
      selected: isSelected,
      'btn btn--secondary hover:!bg-brandLight hover:!border-brandLight hover:!text-darkest': design === 'withText',
    }"
    @click="toggleFavoriteBrand"
  >
    <img
      :src="isSelected ? '/icons/heart-filled.svg' : '/icons/heart.svg'"
      class="inline-block"
      alt=""
      :class="{
        'favoriteAnimateIn': isSelected,
        'w-16 mr-8': design === 'withText',
        'w-20 opacity-100': design === 'minimal' && isSelected,
        'w-20 opacity-55 hover:opacity-100 hover:scale-110 transition-all': !isSelected,
      }"
    >
    <span v-if="design === 'withText'">
      {{ isSelected ? $t('favorite.button.remove.brand') : $t('favorite.button.add.brand') }}
    </span>
  </button>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';
const userStore = useUserStore();

const props = defineProps<{
  brandId: string;
  design: 'withText' | 'minimal';
}>();

const isSelected = computed(()=> {
  return userStore.userProfile.favoriteBrands.includes(props.brandId);
});

const toggleFavoriteBrand = () => {
  if (!isSelected.value) {
    userStore.addFavoriteBrand(props.brandId);
  } else {
    userStore.removeFavoriteBrand(props.brandId);
  }
};

</script>

<style scoped lang="postcss">
</style>
