<template>
  <component
    :is="uiStore.isMobile ? GenericSideMenu : 'div'"
    class="FilterPanel"
    :title="$t('filter.modalTitle')"
    content-class="!p-0"
    :self-contained="true"
    :no-background="true"
    position="left"
    @close="$emit('closeFilter')"
  >
    <div>
      <div v-for="(value, index) in shownOptions" :key="value.categoryKey">
        <FilterPanelButtons
          :value="value"
          :position="index"
          @set-filter="setSelected(value.categoryKey, $event)"
          @reset="resetCategory(value.categoryKey)"
          @close="$emit('closeFilter')"
        />
      </div>
    </div>

    <template #bottom>
      <div class="flex w-full p-16 overflow-hidden bg-lightest border-t border-light">
        <div class="basis-1/4">
          <button class="w-full btn btn--secondary !px-12" @click="resetFilters">
            {{ $t('filter.button.clear') }}
          </button>
        </div>
        <div class="basis-8 shrink-0" />
        <div class="basis-3/4">
          <button class="w-full btn !px-8" @click="$emit('closeFilter')">
            {{ $t('filter.button.show.plur', { num: totalProducts }) }}
          </button>
        </div>
      </div>
    </template>
  </component>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import { useUiStore } from '~/store/ui';
import { AlgoliaFacets, AlgoliaRenderingContent, UserFilters } from '~/constants/types/algolia';
import FilterPanelButtons from '~/components/product/FilterPanelButtons.vue';
import { useGlobalContentStore } from '~/store/globalContent';

const uiStore = useUiStore();
const globalContent = useGlobalContentStore();

export type FilterPanelOption = {
  categoryKey: string,
  options: {
    code: string,
    name: string,
    count: number,
    selected: boolean,
    categoryKey: string,
    parentCode: string | null,
    subAsToplevel: boolean
  }[]
}

const props = defineProps<{
  facetsCurrent: AlgoliaFacets,
  facetsInitial: AlgoliaFacets,
  renderingContent: AlgoliaRenderingContent,
  usedAdminFilter: { filterKey: string, content: string[] }[], // filters used by admin
  totalProducts: number,
  startUserFilter: UserFilters,
}>();
const lastChanged = ref('');

// Purposly breaking props reactivity
const facetsInternal = ref<AlgoliaFacets>(JSON.parse(JSON.stringify(props.facetsInitial)));
const facetsInitial = ref<AlgoliaFacets>(JSON.parse(JSON.stringify(props.facetsInitial)));
/**
 * This does not listen to changes in startUserFilter, this component is the main data point for what filter is selected
 * on load checks the boxes
 */
const userFilters = ref<UserFilters>(JSON.parse(JSON.stringify(props.startUserFilter)));

const emit = defineEmits<{
  (e: 'closeFilter'): void;
  (e: 'setFilter', filter: UserFilters): void;
}>();

const setSelected = (filterKey: string, event: { value: string, selected: boolean}[], scroll = true) => {
  lastChanged.value = filterKey;
  if (!userFilters.value[filterKey]) {
    userFilters.value[filterKey] = [];
  }
  event.forEach((e)=> {
    if (e.selected) {
      userFilters.value[filterKey].push(e.value);
    } else {
      userFilters.value[filterKey] = userFilters.value[filterKey].filter((f) => f !== e.value);
    }
  });
  emit('setFilter', userFilters.value, scroll);
};

const resetFilters = () => {
  userFilters.value = {};
  emit('setFilter', userFilters.value);
};

const resetCategory = (filterKey: string) => {
  delete userFilters.value[filterKey];
  emit('setFilter', userFilters.value);
};

/*
 Strategy from previous version:
 a) The filter user are currently changing does not change count
 b) If only one filter has selection again (from user unchecking other group)
 this filter is reset to init value
 c) If no filter is selected, return to inital value

 @important - development note; chagnes here don't work well with hot reload
 */
watch(()=> props.facetsCurrent, (newVal) => {
  const newKeys = Object.keys(newVal);
  newKeys.forEach((f) => {
    let otherFilters = false;
    Object.keys(userFilters.value).forEach((of) => {
      if (f !== of && userFilters.value[of].length) {
        otherFilters = true;
      }
    });

    // Update if not last changed or if empty again
    if (f !== lastChanged.value || !userFilters.value[f] || !userFilters.value[f].length) {
      facetsInternal.value[f] = newVal[f];
    }

    // If no other filters, we reset current filter to original
    if (!otherFilters) {
      facetsInternal.value[f] = JSON.parse(JSON.stringify(facetsInitial.value[f]));
    }
  });
  // Check if any of the filters we already have is removed
  // This happens when there are zero options, algolia removes the key entierly
  //console.log(facetsInternal.value);
  Object.keys(facetsInternal.value).forEach((fi) => {
    if (!newKeys.includes(fi) && facetsInternal.value[fi]) {
      facetsInternal.value[fi] = {};
    }
  });
});

const shownOptions = computed<FilterPanelOption[]>(()=> {
  if (!props.renderingContent.facetOrdering?.facets?.order) {
    console.warn('no facetorder');
    return [];
  }

  const output = props.renderingContent.facetOrdering.facets.order.map((categoryKey) => {
    if (!facetsInternal.value[categoryKey]) {
      return {
        categoryKey: categoryKey,
        options: [],
      };
    }
    const options = Object.keys(facetsInternal.value[categoryKey]).map((optionKey) => {
      const selected = (userFilters.value && userFilters.value[categoryKey] && userFilters.value[categoryKey].includes(optionKey)) || false;
      return {
        name: optionKey,
        code: optionKey,
        count: facetsInternal.value[categoryKey][optionKey],
        selected,
        categoryKey,
        parentCode: globalContent.getFilterOption(categoryKey + '.' + optionKey).parentCode ?? '',
        subAsToplevel: false,
      };
    });
    const sortOrder = props.renderingContent.facetOrdering?.values[categoryKey]?.sortRemainingBy ?? 'count';

    if (sortOrder === 'alpha') {
      options.sort((a, b) => {
        return globalContent.getFilterOption(categoryKey + '.' + a.code).name
          .localeCompare(globalContent.getFilterOption(categoryKey + '.' + b.code).name);
      });
    }
    if (sortOrder === 'count') {
      options.sort((a, b) => {
        return a.count > b.count ? -1 : 1;
      });
    }

    /**
     * If we ever add manual ordering, this is where
     */

    return {
      categoryKey: categoryKey,
      options,
    };
  });
  // Removes filter used by admin and empty options
  const usedFilterKeys = props.usedAdminFilter?.map((m)=> m.filterKey) ?? [];
  const usedCategory = props.usedAdminFilter?.find((f)=> f.filterKey === 'categoryFilters');
  let usedCategories = [] as string[];
  if (usedCategory) {
    usedCategories = usedCategory.content.map((m2) => 'categoryFilters.' + m2);
  }

  const outputFilter1 =  output.filter((f) =>  {
    return !(usedFilterKeys.includes(f.categoryKey) && f.categoryKey !== 'categoryFilters');
  });

  // We make a special for categories
  // We look for subcategories and flag them subAsToplevel
  // Filter panel will only show them instead of real toplevel
  const categoriesOptions = output.findIndex((ind) => ind.categoryKey === 'categoryFilters');
  if (usedCategories.length && categoriesOptions > -1 ) {
    outputFilter1[categoriesOptions].options = outputFilter1[categoriesOptions].options.filter((co)=> {
      return usedCategories.includes(co.parentCode);
    }).map((o)=> {
      return {
        ...o,
        subAsToplevel: true,
      };
    });
  }
  return outputFilter1.filter((f) => f.options.length);
});

defineExpose({
  setSelected,
});
</script>

<style scoped lang="postcss">
</style>
